<template>
  <div class="pay-money">
    <div class="shop-infor">
      <div class="shop-infor-name">山东顺通石化济南</div>
      <div class="shop-infor-address">工业南路与莲花路</div>
      <div class="shop-infor-price">￥<span>5.66</span><s>￥5.66</s></div>
      <div class="shop-infor-head">
        <img src="@static/vip/chuzhi.png" alt="">
      </div>
    </div>
    <!--选择油号-->
    <div class="choose-oiltype">
       <div class="oil-type">
         <div class="oil-type-title">选择油号</div>
          <div class="oil-type-con">
            <span 
              :class="oilType == idx ? 'active':''" 
              @click="oilType = idx" 
              v-for="(item, idx) in oilTypeArr" 
              :key="idx"
            >
              {{item}}
            </span>
          </div>
       </div>
      <div class="oil-type">
         <div class="oil-type-title">选择油品</div>
          <div class="oil-type-con">
            <span 
              :class="oilNumber == idx ? 'active':''" 
              @click="oilNumber = idx" 
              v-for="(item, idx) in 9" 
              :key="idx"
            >
              {{item}}号
            </span>
          </div>
       </div>
    </div>
    <div class="msg-info">
      <icon name="warning-o" />
      <div class="msg-info-con">
        <span>重要提示</span>
        <p>请务必 <span style="color:#E71D36">先到达油站</span> 与工作人员确认后付款，切勿先买单后付款，避免异常订单的产生!</p>
      </div>
    </div>
    <div class="msg-btn">
      <Button type="warning" style="background:#f9cdad" block text="确定" @click="payMoney"></Button>
      <span>
        若无您选择的油枪号，请联系油站工作人员 <br>
        支付前请确认当前油站地址是否正确
      </span>
    </div>
  </div>
</template>
<script>
import { icon, Button } from "vant";
export default {
  components: {
    icon,
    Button
  },
  data() {
    return {
      oilType: "",
      oilTypeArr: ["#92", "#98", "#0"],
      oilNumber: ""
    }
  },
  methods: {
    payMoney() {
      this.$router.push('goMoney')
    }
  }
}
</script>
<style lang="scss" scoped>
.pay-money {
  position: relative;
  height: 100%;
  overflow: hidden;
  .shop-infor {
    height:105px;
    background:rgba(255,255,255,1);
    margin-top: 4px;
    padding: 16px 14px 18px;
    position: relative;

    .shop-infor-name {
      font-size:17px;
      font-weight:400;
      color:rgba(31,31,31,1);
      line-height:17px;
    }

    .shop-infor-address {
      font-weight:400;
      color:rgba(136,136,136,1);
      line-height:12px;
      height:12px;
      font-size:12px;
      margin-top: 12px;
    }

    .shop-infor-price {
      margin-top: 15px;
      line-height: 15px;
      color: #666666;

      span {
        font-size: 18px;
        font-weight: 400;
        color:rgba(31,31,31,1);
        margin-right: 15px;
      }
    }

    .shop-infor-head {
      width:44px;
      height:44px;
      position: absolute;
      right: 14px;
      top: 50%;
      margin-top: -22px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .choose-oiltype {
    width: 100%;
    height: auto;
    margin-top: 10px;
    display: flex;  
    flex-direction: column;
    background: #ffffff;
    .oil-type {
      width: 100%;
      height: auto;
    }
    .oil-type-title {
      width: 93%;
      height: 50px;
      line-height: 50px;
      font-size: 14px;
      margin: 0 auto;
      color: #363636;
      border-bottom: 1px solid #f1f1f1;
    }
    .oil-type-con {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: flex-start;
      padding: 0 14px;
      margin-left: -10px;
      flex-wrap: wrap;
      padding-bottom: 10px;
      span {
        display: block;
        width: auto;
        height: 30px;
        padding: 0 18px;
        text-align: center;
        line-height: 30px;
        border-radius: 16px;
        background: #f2f2f2;
        margin-left: 10px;
        margin-top: 10px;
      }
      span.active {
        color: red;
        background: rgba(249, 205, 173, 0.521);
      }
    }
  }
  .msg-info {
    width: 100%;
    height: 100px;
    background: rgb(249, 205, 173);
    padding: 10px 20px;
    color: #f2f2f2;
    display: flex;
    justify-content: space-between;
    .msg-info-con{
      padding-left: 10px;
      font-size: 16px;
      padding-left: 8px;
      span {
        font-weight: 570;
        font-size: 16px;
        font-weight: 560;
      }
      p {
        font-size: 14px;
      }
    }
    .van-icon-warning-o {
      font-size: 24px !important;
      color: #fff !important;
    }
  }
  .msg-btn {
    position: absolute;
    width: 100%;
    height: 100px;
    bottom: 10px;
    left: 0;
    span {
      display: block;
      line-height: 20px;
      width: 72%;
      font-size: 14px;
      margin: 8px auto;
      text-align: center;
    }
  }
}

</style>